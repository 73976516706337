import React, { useEffect } from 'react';
import imgMsoStage from '../../assets/units/MSO/stageMSO.jpg';
import imgMsoBuilding from '../../assets/units/MSO/mso-building.jpg';
import imgCircleHall from '../../assets/units/MSO/circleHall.jpg';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';
import imgCustomerSupport from '../../assets/customer-support.png';
import imgPromotion from '../../assets/promotion.png';
import imgRelationship from '../../assets/relationship.png';
import { Col, Row } from 'react-bootstrap';


function MSO() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // features card carousel data
    const featuresData = [
        {
            title: "Customer Support",
            description: "Offering dedicated assistance and guidance to our clients in Malaysia.",
            image: imgCustomerSupport
        },
        {
            title: "Product Promotion",
            description: "Actively promoting and showcasing our top-tier products to meet diverse customer needs.",
            image: imgPromotion
        },
        {
            title: "Relationship Building",
            description: "Fostering lasting relationships with clients through personalized engagement.",
            image: imgRelationship
        }
        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <CenterStage
                imageSrc={imgMsoStage}
                title={"Malaysia Sales Office - HANSA-FLEX India"}
                paragraph={"Welcome to the Malaysia Sales Office of HANSA-FLEX India, inaugurated in 2024 as a strategic initiative to fortify our presence in Malaysia. Dedicated to providing exceptional support to our valued customers in the region, the sales office focuses on promoting our high-quality products"}
            />

            <div style={{ marginTop: '-100px' }}>
                <SectionHeader title={"Here are some key activities"} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div>

                <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                    <Row>
                        <Col xs={12} md={6}>
                            <h2>Contact Details:</h2>
                            <p>For inquiries, assistance, or collaboration opportunities, please reach out to our Malaysia Sales Office:</p>
                            <p>Address: Tower Regency Hotel & Apartments, 6-8, Jln Dato Seri Ahmad Said, 30450 Ipoh, Perak, Malaysia</p>
                            <p>Email: <a href="mailto:mso@hansa-flex.com" style={{ color: 'white' }}>mso@hansa-flex.com</a></p>
                        </Col>
                    </Row>
                </div>


            </div>
        </div>
    );
}

export default MSO;