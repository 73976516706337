import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';

import imgMHP from '../../assets/units/MHP/mhp.jpg';
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import ImageText from '../../Components/ImageText/ImageText';
import imgTig from '../../assets/units/MHP/tig.png';
import imgLazerWelding from '../../assets/units/MHP/LaserWelding-02.png';
import imgBrazing from '../../assets/units/MHP/Brazing-02.png';
import imgMark from '../../assets/mark.png';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import NumberGrid from '../../Components/NumberGrid/NumberGrid';
import { Row, Col } from 'react-bootstrap';
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";


function MHP() {

    // TODO: paragraphs need to be on seperate lines not combined
    //TODO: Add Certificates

    const dataTIG = {
        image: imgTig,
        title: 'TIG Welding',
        listItems: ['TIG welding is a sophisticated process that creates high-quality welds using a tungsten electrode and inert gas.', ' It’s widely used in various industries, including aerospace and automotive manufacturing.']
    }

    const dataLazerWelding = {
        image: imgLazerWelding,
        title: 'Laser Welding',
        description: "HANSA-FLEX utilizes laser welding technology to deliver precise and efficient welds, enhancing production processes with its capability for intricate work. Our commitment to innovation ensures high-quality results and optimal performance in modern manufacturing operations."
    }

    const dataBrazing = {
        image: imgBrazing,
        title: 'Brazing',
        description: "Brazing is a metal-joining process where two or more metal items are joined by melting and flowing a filler metal into the joint. Unlike welding, brazing does not involve melting the workpieces themselves."
    }

    //features card data
    const featuresData = [
        {
            title: "ISO 3834",
            description: " ",
            image: imgMark
        },
        {
            title: "EN15085 CL-1",
            description: " ",
            image: imgMark
        },
        {
            title: "DNV- GL",
            description: " ",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //hall of fame
    const numbers = ['35+', '8000+', '25+', '200%'];
    const subtitles = ['Employees', 'production of hoses per month', 'Active customers', 'Growth percent'];


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage
                imageSrc={imgMHP}
                title="Welcome to HANSA-FLEX India's Metal Hose Production Unit."
                paragraph="The journey of HANSA-FLEX India commenced with this unit initially focusing on serial hose production. Over time, our operations expanded, prompting the relocation of our serial hose production activities to the new SHP unit. Since 2023, this facility has evolved into our dedicated Metal Hose Production Unit.
                Committed to maintaining the highest standards, our plant proudly holds certifications for ISO 3834, EN15085 CL-1 for Railway Application, and DNV-GL for Marine Application. These certifications represent the pinnacle in welding standards, ensuring the utmost quality and reliability in our metal hose production processes." />

            <div>
                <SectionHeader
                    title={"We specilize in following types of Welding"} />

                <br />
                <ImageText data={dataTIG} /> <br /><br />
                <ImageText data={dataLazerWelding} left={false} /><br /><br />
                <ImageText data={dataBrazing} /> <br /><br />

            </div>

            <div>
                <SectionHeader title={"Our Approvals"} subtitle={"This plant is certified with ISO 3834, EN15085 CL-1 for Railway Application Image and DNV- GL for Marine Application Image. The Highest level of Certification for Welding.  "} />
                <FeatureCardCarousel featuresData={featuresData} />

            </div>

            <div>
                <br /><br />
                <SectionHeader
                    title={"MHP in Numbers"} /><br />
                <NumberGrid numbers={numbers} subtitles={subtitles} /><br /><br />
            </div>

            <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Contact Details:</h2>
                        <div>
                            <p>
                                <MdLocationOn /> HANSA-FLEX INDIA PVT. LTD. <br />
                                Plot no. E 3-4, MIDC Phase – III, Chakan Industrial Area, Kuruli & Nighoje, <br />
                                Near Mercedes-Benz, Taluka - Khed, Dist - Pune, Maharashtra - 410501, India.
                            </p>
                            <p>
                                <MdEmail /> Email: <a href="mailto:inp@hansa-flex.com" style={{ color: 'white' }}>inp@hansa-flex.com</a>
                            </p>
                            <p>
                                <MdPhone /> Telephone: 02135-663939
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>


    );
}

export default MHP;